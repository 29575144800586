import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    em: "em",
    h2: "h2",
    li: "li",
    p: "p",
    pre: "pre",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(_components.p, {
      children: ["Our implementation of ", _jsx(_components.em, {
        children: "feature flags"
      }), " is actually more like ", _jsx(_components.strong, {
        children: "feature modules"
      }), ", where a module provides add-on functionality to the base application. Here is what we define as a ", _jsx(_components.em, {
        children: "feature flag"
      }), ":"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Feature flags are a set of booleans that can be toggled on or off at runtime to modify the app. They are actually implemented as native ES modules with a module API contract."
      })
    }), "\n", _jsx(_components.h2, {
      id: "feature-flags-as-es-modules",
      children: _jsx(_components.a, {
        href: "#feature-flags-as-es-modules",
        children: "Feature flags as ES modules"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["This is really the primary takeaway of this document: each ", _jsx(_components.code, {
        children: "feature-"
      }), " directory compiles into a standalone module using ", _jsx(_components.code, {
        children: "mod.mjs"
      }), " as its entry point. Features are expected to be self-contained, and may not leak into the base application."]
    }), "\n", _jsx(_components.h2, {
      id: "how-does-this-differ-from-services-like-optimizely-launchdarkly-google-optimize",
      children: _jsx(_components.a, {
        href: "#how-does-this-differ-from-services-like-optimizely-launchdarkly-google-optimize",
        children: "How does this differ from services like Optimizely, Launchdarkly, Google Optimize?"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["These services provide a centralized backend to conditionally toggle a set of boolean values based on user info. To actually integrate these services with application code, requires ", _jsx(_components.a, {
        href: "https://docs.launchdarkly.com/guides/best-practices/improving-code",
        children: "hardcoding conditionals in application code"
      }), "."]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "We are doing something different"
      }), ": instead of conditionals in the base application, we are monkeypatching the base application from feature modules. This has a few advantages:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "The base application is free of feature-flag code, it can be agnostic about what features exist."
      }), "\n", _jsx(_components.li, {
        children: "Feature flags are toggled in runtime rather than as initialization or build-time, this is a huge benefit for certain use cases like toggling Pro features on/off, or ads."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "There are drawbacks:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Increased complexity, requires entry points to monkeypatch, handling setup/teardown."
      }), "\n", _jsx(_components.li, {
        children: "Minor increase in startup time, since the app will wait for all requested feature modules to be loaded."
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "when-to-use-feature-flags",
      children: _jsx(_components.a, {
        href: "#when-to-use-feature-flags",
        children: "When to use feature flags"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["A feature flag should only be used ", _jsx(_components.strong, {
        children: "if there is functionality that needs to be toggled on or off in runtime."
      }), " If it does not meet this criteria, consider implementing the feature as a route instead."]
    }), "\n", _jsx(_components.h2, {
      id: "similarities-to-routes",
      children: _jsx(_components.a, {
        href: "#similarities-to-routes",
        children: "Similarities to routes"
      })
    }), "\n", _jsx(_components.p, {
      children: "There are actually quite a lot of similarities to routes:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Both are loaded only upon a runtime trigger (URL or runtime code)."
      }), "\n", _jsx(_components.li, {
        children: "Both are native ES modules."
      }), "\n", _jsxs(_components.li, {
        children: ["Both have an API contract to fulfill. Routes must export a ", _jsx(_components.code, {
          children: "default"
        }), " component, feature flags must implement ", _jsx(_components.code, {
          children: "setup"
        }), " and ", _jsx(_components.code, {
          children: "teardown"
        }), " functions."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "adding-a-feature-flag",
      children: _jsx(_components.a, {
        href: "#adding-a-feature-flag",
        children: "Adding a feature flag"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Adding a feature flag is as easy as creating a ", _jsx(_components.code, {
        children: "feature-"
      }), " directory with a ", _jsx(_components.code, {
        children: "mod.mjs"
      }), " file. This file must contain:"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "export function setup() {...}\nexport function teardown() {...}\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["This file can import from any other file in the ", _jsx(_components.code, {
        children: "src"
      }), " directory, code-splitting will handle it intelligently."]
    }), "\n", _jsx(_components.p, {
      children: "The build system will pick up on it automatically, and it can be toggled on/off with:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "__BLITZ_DEV__.featureFlags[nameOfFeature] = true || false;\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Feature flags have their default value set in ", _jsx(_components.code, {
        children: "feature-flags.mjs"
      }), ". They can also be toggled programmatically by importing ", _jsx(_components.code, {
        children: "featureFlags"
      }), " from that module."]
    }), "\n", _jsx(_components.h2, {
      id: "runtime-modifications",
      children: _jsx(_components.a, {
        href: "#runtime-modifications",
        children: "Runtime modifications"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Features have ", _jsx(_components.code, {
        children: "setup"
      }), " and ", _jsx(_components.code, {
        children: "teardown"
      }), " functions that are runtime only. They can modify existing components, routes, ", _jsx(_components.em, {
        children: "anything"
      }), "!"]
    }), "\n", _jsxs(_components.p, {
      children: ["The way that feature flags can add, remove, and modify existing components relies on creating ", _jsx(_components.code, {
        children: "refs"
      }), " objects, or entry points in the codebase to hook into. The main ", _jsx(_components.code, {
        children: "App.jsx"
      }), " component has a few entry points of its own, which is used by ", _jsx(_components.code, {
        children: "feature-ads"
      }), " to modify the entire layout."]
    }), "\n", _jsxs(_components.p, {
      children: ["To create ", _jsx(_components.code, {
        children: "refs"
      }), " in any component that needs to be hooked into is very easy. ", _jsx(_components.code, {
        children: "Greeting.refs.jsx"
      }), ":"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import React from \"react\";\n\nconst Component = () => {\n  return <p>Hello world!</p>;\n};\n\nexport default {\n  Component,\n};\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.code, {
        children: "Greeting.jsx"
      }), ":"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import EventedRender from \"@/shared/EventedRender.jsx\";\nimport GreetingRefs from \"@/shared/Greeting.refs.jsx\";\n\nexport default function Greeting() {\n  return (\n    <EventedRender>\n      <GreetingRefs.Component />\n    </EventedRender>\n  );\n}\n"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Then in a feature module (", _jsx(_components.code, {
        children: "mod.mjs"
      }), "):"]
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "import { refs as greetingRefs } from \"...\";\nimport { reRender } from \"@/shared/EventedRender.jsx\";\n\nconst originals = {};\n\nexport function setup() {\n  originals.Component = greetingRefs.Component;\n  greetingRefs.Component = () => <p>Goodbye cruel world!</p>;\n  reRender();\n}\n\nexport function teardown() {\n  greetingRefs.Component = originals.Component;\n  reRender();\n}\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "This will replace the existing component with one from the feature flag, and force it to be rendered right away. The teardown function does the opposite."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "IMPORTANT"
      }), ": make sure that the teardown function reverses everything in the setup function."]
    }), "\n", _jsx(_components.h2, {
      id: "decommissioning-a-feature",
      children: _jsx(_components.a, {
        href: "#decommissioning-a-feature",
        children: "Decommissioning a feature"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["One of the best features of feature modules is being able to remove it entirely without extra effort. To shelf a feature but still keep its code, just add the ", _jsx(_components.code, {
        children: "-decommissioned"
      }), " suffix to the directory name. This has the added benefit of reducing build times."]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
